import ApiRoutes from "./ApiRoutes"
import {APIChannel, APIEmoji, APIRole} from "discord-api-types/v10";
import {ContextGuildData, ContextSetters} from "./AppContext";

export class Client {
    public readonly routes: ApiRoutes

    public contextSetters: ContextSetters = {
        logged: (logged: boolean) => {
        },
        user: (user?: User) => {
        },
        guilds: (guilds: GuildUser[]) => {
        },
        currentGuild: (guild?: GuildUser) => {
        },
        currentGuildData: (guildData?: ContextGuildData) => {
        }
    }


    public constructor() {
        this.routes = new ApiRoutes("https://go.enopanel.xyz", 1)
    }

    public get token(): string {
        const token: Token | null = this.getToken()
        if (token == null) {
            throw new ClientError("Unable to find token in localStorage")
        }
        return token.token
    }

    public app = async (): Promise<AppResponse> => {
        return this.makeRequest<AppResponse>(this.routes.app(), {
            method: "GET"
        })
    }

    public login = async (code: string): Promise<boolean> => {
        return this.makeRequestStatus(this.routes.auth(code), {
            method: "GET"
        })
    }

    public logout = () => {
        this.deleteToken()
        this.contextSetters.logged(false)
    }

    public me = async (): Promise<User> => {
        return this.makeRequest<User>(this.routes.me(), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${this.token}`
            }
        })
    }

    public guilds = async (): Promise<GuildUser[]> => {
        return this.makeRequest<GuildUser[]>(this.routes.guilds(), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${this.token}`
            }
        })
    }

    public guild = async (guildId: string): Promise<GuildData> => {
        const data = await this.makeRequest<GuildData>(this.routes.guild(guildId), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${this.token}`
            }
        })

        data.channels = data.channels.map(channel => {
            if (channel.type === undefined) {
                channel.type = 0
            }
            return channel
        })

        return data
    }

    public temporaryChannels = async (guildId: string): Promise<TemporaryChannel[]> => {
        return this.makeRequest<TemporaryChannel[]>(this.routes.temporaryChannels(guildId), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${this.token}`
            }
        })
    }

    public temporaryChannel = async (guildId: string, configId: number): Promise<TemporaryChannel> => {
        return this.makeRequest<TemporaryChannel>(this.routes.temporaryChannel(guildId, configId), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${this.token}`
            }
        })
    }

    public createTemporaryChannel = async (guildId: string, temporaryChannel: Partial<TemporaryChannel>): Promise<TemporaryChannel> => {
        return this.makeRequest<TemporaryChannel>(this.routes.temporaryChannels(guildId), {
            method: "POST",
            body: JSON.stringify(temporaryChannel),
            headers: {
                "Authorization": `Bearer ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    public editTemporaryChannel = async (temporaryChannel: Partial<TemporaryChannel>): Promise<TemporaryChannel> => {
        console.log(temporaryChannel.id)
        return this.makeRequest<TemporaryChannel>(this.routes.temporaryChannel(temporaryChannel.guild_id, temporaryChannel.id), {
            method: "PATCH",
            body: JSON.stringify(temporaryChannel),
            headers: {
                "Authorization": `Bearer ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    public deleteTemporaryChannel = async (guildId: string, temporaryChannelId: number): Promise<boolean> => {
        return this.makeRequestStatus(this.routes.temporaryChannel(guildId, temporaryChannelId), {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.token}`
            }
        })
    }

    public async makeRequestStatus(input: RequestInfo | URL, init?: RequestInit): Promise<boolean> {
        const response = await fetch(input, init)
        if (response.status < 200 || response.status > 299) {
            return false
        }
        if (response.headers.has("New-Token")) {
            const rawToken: string | null = response.headers.get("New-Token")
            if (rawToken) {
                this.setToken(JSON.parse(window.atob(rawToken)))
            }
        }
        return true
    }

    public async makeRequest<ResponseType>(input: RequestInfo | URL, init?: RequestInit): Promise<ResponseType> {
        const response = await fetch(input, init)
        if (response.status < 200 || response.status > 299) {
            if (response.status === 404) {
                throw new ClientError("Cette ressource n'existe pas (ou plus).")
            } else {
                const data = await response.json()
                throw new ClientError(data.error)
            }
        }
        return response.json()
    }

    private deleteToken = (): void => {
        window.localStorage.removeItem("auth")
    }

    private setToken = (token: Token): void => {
        window.localStorage.setItem("auth", JSON.stringify(token))
    }

    private getToken = (): Token | null => {
        const token: string | null = window.localStorage.getItem("auth")
        if (!token) {
            return null
        }
        return JSON.parse(token)
    }
}

const client: Client = new Client()
export default client

export type AppResponse = {
    auth_url: string
}

export type User = {
    id: number
    username: string
    discriminator: string
    avatar?: string
}

export type GuildUser = {
    id: string
    name: string
    icon: string
    owner: boolean
    permissions: number
    features: string[]
    has_bot: boolean
}

export type GuildData = {
    emojis: APIEmoji[]
    roles: APIRole[]
    channels: APIChannel[]
    forum_posts: APIChannel[]
}

export type TemporaryChannel = {
    id: number
    guild_id: string
    category_id: string
    owner_role_id: string | null
    prefix: string | null
    slots: {
        min: number
        max: number
        default: number
    }
    permissions: {
        can_rename: boolean
        can_invite: boolean
        can_kick: boolean
        can_ban: boolean
        can_call_staff: boolean
        can_add_bot: boolean
    }
    channels: {
        creation_id: string
        ping_staff_id: string | null
        invitation_id: string | null
        logs_id: string | null
    }
    view_roles: string[]
    join_roles: string[]
    ping_roles: string[]
    staff_roles: string[]
}

type Token = {
    token: string
    expires_at: string
}

export class ClientError extends Error {
}