import AppContext, {ContextGuildData, ContextType} from "./AppContext"
import {PropsWithChildren, useState} from "react"
import client, {GuildUser, User} from "./Client"

export default function AppContextProvider({children}: PropsWithChildren<{}>) {
    const [logged, setLogged] = useState<boolean>(false)
    const [user, setUser] = useState<User | undefined>(undefined)
    const [guilds, setGuilds] = useState<GuildUser[]>([])
    const [currentGuild, setCurrentGuild] = useState<GuildUser | undefined>(undefined)
    const [currentGuildData, setCurrentGuildData] = useState<ContextGuildData | undefined>(undefined)

    const context: ContextType = {
        logged,
        user,
        guilds,
        currentGuild,
        currentGuildData
    }

    client.contextSetters = {
        logged: setLogged,
        user: setUser,
        guilds: setGuilds,
        currentGuild: setCurrentGuild,
        currentGuildData: setCurrentGuildData
    }

    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    )
}