import {useRoutes} from 'react-router-dom';
import router from 'router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import React from "react";
import BaseLayout from "./layouts/BaseLayout";
import Login from "./content/home/Login";
import SuspenseLoader from "./components/SuspenseLoader";
import AppContext , {ContextType} from "./enopanel/eno/AppContext";
import client from "./enopanel/eno/Client";

class App extends React.Component<{}, States> {

    static contextType = AppContext;

    context: ContextType;

    state: States = {
        loading: true,
        authUrl: ''
    };

    componentDidMount() {
        this.init().then(() => {
            console.info("Application successfully initialized!")
        })
    }

    private init = async () => {
        try {
            const user = await client.me()
            const guilds = await client.guilds()
            client.contextSetters.logged(true)
            client.contextSetters.user(user)
            client.contextSetters.guilds(guilds)
            this.setState({
                loading: false
            })
        } catch (e) {
            const app = await client.app()
            this.setState({
                authUrl: app.auth_url,
                loading: false
            })
        }
    }

    render() {
        return (
            <>
                {this.state.loading || !this.context.logged ? (
                    <ThemeProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CssBaseline/>
                            <BaseLayout>
                                {this.state.loading ? (<SuspenseLoader text="Chargement en cours"/>) : <Login authUrl={this.state.authUrl}/>}
                            </BaseLayout>
                        </LocalizationProvider>
                    </ThemeProvider>
                ) : (
                    <LoggedApp/>
                )}
            </>
        );
    }
}

function LoggedApp() {
    const content = useRoutes(router());

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline/>
                {content}
            </LocalizationProvider>
        </ThemeProvider>
    );
}

type States = {
    loading: boolean,
    authUrl: string
}

export default App;
