import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import BaseLayout from 'layouts/BaseLayout';

import SuspenseLoader from 'components/SuspenseLoader';
import PanelLayout from "./layouts/PanelLayout";
import {LoginRedirect} from "./content/home/Login";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const GuildSelection = Loader(lazy(() => import('content/panel/GuildSelection')));

const TemporaryChannels = Loader(lazy(() => import('content/panel/Valkyrie/TemporaryChannels')));


// Status

const Status404 = Loader(
  lazy(() => import('content/pages/Status/Status404'))
);

const router = (props = {}): RouteObject[] => {
  return [
    {
      path: 'login',
      element: <LoginRedirect/>
    },
    {
      path: '',
      element: <BaseLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/app/guilds"/>
        },
        {
          path: 'app',
          element: <PanelLayout/>,
          children: [
            {
              path: '',
              element: <Navigate to="/app/guilds"/>
            },
            {
              path: 'guilds',
              element: <GuildSelection/>
            },
            {
              path: 'valkyrie/temporary-channels',
              element: <TemporaryChannels/>
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Status404/>
    }
  ]
}

export default router;
