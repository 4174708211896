import {useContext, useRef, useState} from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AppContext, {ContextType} from "../../../../enopanel/eno/AppContext";
import client from "../../../../enopanel/eno/Client";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const context: ContextType = useContext(AppContext);
  const user = {
    name: `${context.user.username}#${context.user.discriminator}`,
    avatar: context.user.avatar ? (`https://cdn.discordapp.com/avatars/${context.user.id}/${context.user.avatar}.` + (context.user.avatar.startsWith('a_') ? 'gif': 'png')) : '/static/images/placeholders/discord_avatar.png',
    jobtitle: context.currentGuild ? context.currentGuild.name : 'Aucun serveur sélectionné'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem onClick={handleClose} button to="/app/guilds" component={NavLink}>
            <DisplaySettingsTwoToneIcon fontSize="small" />
            <ListItemText primary="Sélectionner un serveur" />
          </ListItem>
          <ListItem onClick={handleClose} button to="/app/premium" component={NavLink}>
            <StarBorderOutlined fontSize="small" />
            <ListItemText primary="Abonnement Premium" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={client.logout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Se déconnecter
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
