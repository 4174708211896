export default class ApiRoutes {
    private readonly endpoint: string

    public constructor(endpoint: string, private readonly version: number) {
        this.endpoint = `${endpoint}/api/v${this.version}`
    }

    public app = (): string => `${this.endpoint}/app`
    public auth = (code: string): string => `${this.endpoint}/login?code=${code}`
    public me = (): string => `${this.endpoint}/me`

    public guilds = (): string => `${this.endpoint}/guilds`
    public guild = (guildId: string): string => `${this.guilds()}/${guildId}`

    public temporaryChannels = (guildId: string): string => `${this.guild(guildId)}/temporary-channels`
    public temporaryChannel = (guildId: string, configId: number): string => `${this.temporaryChannels(guildId)}/${configId}`
}