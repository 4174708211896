import React from "react";
import {Box, Button} from "@mui/material";
import AppContext, {ContextType} from "../../enopanel/eno/AppContext";
import NProgress from 'nprogress';
import {Navigate} from 'react-router-dom';
import client from "../../enopanel/eno/Client";

class Login extends React.Component<Props, {}> {

    static contextType = AppContext;

    context: ContextType;

    componentDidMount() {
        this.init()
    }

    private init = async () => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        if (params.code) {
            NProgress.start();
            if (await client.login(params.code)) {
                const user = await client.me()
                const guilds = await client.guilds()
                NProgress.done();
                client.contextSetters.user(user)
                client.contextSetters.guilds(guilds)
                client.contextSetters.logged(true)

            }
        }
    }

    render() {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%'
                }}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center">
                <a style={{
                    textDecoration: 'none'
                }} href={this.props.authUrl}>
                    <Button
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: 'solid 1px #5865f2',
                            backgroundColor: 'transparent',
                            color: '#5865f2'
                        }}
                        size="large"
                        variant="contained">
                        <img style={{
                            marginRight: 5,
                            height: 30,
                            width: 30
                        }} src="/static/images/logo/discord.svg"
                             alt="discord-logo"/>
                        Connexion avec Discord
                    </Button>
                </a>
            </Box>
        );
    }
}

type Props = {
    authUrl: string
}

export function LoginRedirect() {
    return <Navigate to="/app/guilds"/>
}

export default Login;