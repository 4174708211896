import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'App';
import {SidebarProvider} from 'contexts/SidebarContext';
import * as serviceWorker from 'serviceWorker';
import AppContextProvider from "enopanel/eno/AppContextProvider";

ReactDOM.render(
    <AppContextProvider>
        <HelmetProvider>
            <SidebarProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </SidebarProvider>
        </HelmetProvider>,
    </AppContextProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
