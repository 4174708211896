import {createContext} from "react";
import {GuildData, GuildUser, User} from "./Client";

const AppContext = createContext<ContextType>({
    logged: false,
    guilds: []
});

export type ContextType = {
    logged: boolean,
    user?: User,
    guilds: GuildUser[],
    currentGuild?: GuildUser,
    currentGuildData?: ContextGuildData
}

export type ContextSetters = {
    logged: (logged: boolean) => void
    user: (user?: User) => void
    guilds: (guilds: GuildUser[]) => void
    currentGuild: (guild?: GuildUser) => void
    currentGuildData: (guildData?: ContextGuildData) => void
}

export type ContextGuildData = GuildData & {

}

export default AppContext;